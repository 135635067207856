var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _c(
          "div",
          {
            staticStyle: { overflow: "hidden" },
            attrs: { id: "body-content-area" },
          },
          [
            _vm.isLoading
              ? _c("div", [_c("Loading")], 1)
              : _c("div", [
                  _c("div", { attrs: { id: "pagelayout" } }, [
                    _vm.errors && _vm.errors.length
                      ? _c(
                          "div",
                          _vm._l(_vm.errrors, function (error) {
                            return _c("div", { key: error }, [
                              _vm._v(_vm._s(error)),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.institutions && _vm.institutions.length > 0
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "grid-container" },
                            [
                              _c("div", { staticClass: "bottom-bar" }, [
                                _c("div", { staticClass: "buttons" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-accent",
                                      attrs: {
                                        disabled: !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["ANNOUNCEMENTS"],
                                          2
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "AnnouncementDetailsNew",
                                            model: null,
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-plus",
                                        staticStyle: {
                                          "margin-right": ".75rem",
                                          "margin-left": "0",
                                        },
                                      }),
                                      _vm._v("Add Announcement"),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      class: [
                                        "button is-dark ",
                                        _vm.selectedRows.length < 1
                                          ? "disabled"
                                          : "",
                                        _vm.isDeleting ? "is-loading" : "",
                                      ],
                                      attrs: {
                                        disabled: _vm.selectedRows.length < 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          !_vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["ANNOUNCEMENTS"],
                                            2
                                          )
                                            ? null
                                            : (_vm.showDeleteModal = true)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-trash",
                                        staticStyle: {
                                          "margin-right": ".75rem",
                                          "margin-left": "0",
                                        },
                                      }),
                                      _vm._v(
                                        "Delete Announcement" +
                                          _vm._s(
                                            _vm.selectedRows.length > 1
                                              ? "s"
                                              : ""
                                          )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm.maxHeight > 0
                                ? _c("Grid", {
                                    style: {
                                      maxheight: _vm.maxHeight + "px",
                                      height: _vm.maxHeight + "px",
                                    },
                                    attrs: {
                                      "data-items": _vm.announcementsList,
                                      columns: _vm.columns,
                                      "selected-field": _vm.selectedField,
                                      sortable: true,
                                      take: _vm.pagination.take,
                                      skip: _vm.pagination.skip,
                                      pageable: Object.assign(
                                        {},
                                        _vm.pageable,
                                        { pageSizes: _vm.pageSizes }
                                      ),
                                      "page-size": _vm.minResults,
                                      total: _vm.totalRecords,
                                      sort: _vm.sort,
                                    },
                                    on: {
                                      headerselectionchange:
                                        _vm.onHeaderSelectionChange,
                                      selectionchange: _vm.onSelectionChange,
                                      pagechange: _vm.pageChangeHandler,
                                      sortchange: _vm.sortchange,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "nameCell",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "has-text-accent",
                                                      attrs: {
                                                        to: {
                                                          name: "AnnouncementDetails",
                                                          params: {
                                                            id:
                                                              "" +
                                                              props.dataItem.id,
                                                            model:
                                                              "" +
                                                              JSON.stringify(
                                                                props.dataItem
                                                              ),
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            props.dataItem.name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "institution",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c("td", [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.getInstitutionCells(
                                                        props.dataItem
                                                          .institutionIds
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "startDate",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDateInLocalTimezone(
                                                        props.dataItem
                                                          .startDate,
                                                        "YYYY-MM-DD hh:mm A"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "expireDate",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDateInLocalTimezone(
                                                        props.dataItem
                                                          .expirationDate,
                                                        "YYYY-MM-DD hh:mm A"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "isActive",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getIsActive(
                                                        props.dataItem
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1502388977
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
          ]
        ),
      ]),
      _vm.showDeleteModal
        ? _c(
            "div",
            { staticClass: "modal is-active", attrs: { id: "delete-modal" } },
            [
              _c("div", {
                staticClass: "modal-background",
                on: {
                  click: function ($event) {
                    _vm.showDeleteModal = false
                  },
                },
              }),
              _c("div", { staticClass: "modal-card" }, [
                _c("header", { staticClass: "modal-card-head has-bg-danger" }, [
                  _c("div", { staticClass: "modal-card-title has-bg-danger" }, [
                    _vm._v("Confirm Delete"),
                  ]),
                  _c("a", {
                    staticClass: "delete",
                    attrs: { "aria-label": "close" },
                    on: {
                      click: function ($event) {
                        _vm.showDeleteModal = false
                      },
                    },
                  }),
                ]),
                _c("section", { staticClass: "modal-card-body" }, [
                  _c("div", { staticClass: "content" }, [
                    _vm._v(' Type "'),
                    _c("b", [_vm._v("delete")]),
                    _vm._v(
                      '" to confirm that you want to delete ' +
                        _vm._s(_vm.selectedRows.length > 1 ? "these" : "this") +
                        " announcement" +
                        _vm._s(_vm.selectedRows.length > 1 ? "s" : "") +
                        ". "
                    ),
                  ]),
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.confirmationText,
                            expression: "confirmationText",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "text",
                          placeholder: "type `delete` and then click confirm",
                        },
                        domProps: { value: _vm.confirmationText },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.deleteIfConfirmed.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmationText = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("footer", { staticClass: "modal-card-foot" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button is-danger",
                      attrs: { disabled: _vm.confirmationText !== "delete" },
                      on: { click: _vm.deleteIfConfirmed },
                    },
                    [_vm._m(0), _c("span", [_vm._v("Confirm")])]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          _vm.showDeleteModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }